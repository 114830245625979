


.root-game{
    background: url("../styles/img/wallpaper-art-image.jpg");
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-game-page-root{
    background-image: url('../styles/img/wallpaper-art-image.jpg');
        background-size: cover;
        background-position: 0 64px;
        background-repeat:no-repeat;
        background-attachment: fixed;
        min-height: 100vh;
        max-width: 100vw;
}
.abacus thead, .abacus tbody{
    display:inline-block;
}

.abacus-table-element{
    width: 6.5vw;
    height: 4.5vw;
}

.abacus-table-element-number{
    font-size: 3vw;
    color: darkblue;
    text-align: center;
    background: white;
    border-radius: 50%;
    margin: 5px;
    font-weight: bold;

}

.abacus-table-chip{
    background: url("../styles/img/abacus-chip.png") center center no-repeat;
    background-size: 6vw;
    width:6.5vw;
    height: 4.5vw;
}

.abacus-table-tbody{
    border: black 5px solid;
}

.abacus-table-column{
    background: url("../styles/img/line.png") center center no-repeat;
}

.abacus-table-top{
    border-top: black 3px solid;
    margin: 0;
}

.abacus-table-down{
    border-bottom: black 3px solid;
    margin: 0;
}