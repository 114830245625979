
.table-wrapper-asks {
    overflow: scroll;
    margin-top: 10px;
}

.numberOfTable {
    height: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    background-color: #cbcbcb;
    border: 2rem #dd6640;
}


.table-wrapper-answers {
    overflow: scroll;
    background-color: #ffffff;
    margin-top: 20px;
}

.head-answers {
    width: 100%;
    height: 30px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
}

table {
    position: relative;
    border: 1px solid #ddd;
    border-collapse: collapse;
}

.td-class {
    background-color: rgba(169, 238, 165, 0.51);
    width: 200px;
}

.td-class-for-answer {
    background-color: rgba(169, 238, 165, 0.22);
    width: 200px;
    height: 40px;
    border: 1px solid #dd6640;
}

.td-class-for-answer:nth-child(even) {
    background-color: rgba(242, 242, 242, 0.25);
}

.td-class:nth-child(even) {
    background-color: #f2f2f2;
}

.td-class, th-class, td-class-for-answer {
    white-space: nowrap;
    border: 1px solid #dd6640;
    padding: 10px;
    width: 200px;
    height: 40px;
    text-align: center;
}

.th-class {
    background-color: #eee;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 2;

}

.columns-in-table {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.column-container-in-table {
    white-space: nowrap;
    border: 1px solid #dd6640;
    width: 200px;
    text-align: center;
}


.rows-in-table {
    display: flex;
    flex-direction: row;
}

input {
    width: 97%;
    height: 98%;
    background-color: #FFFFFF;
}

#title {
    text-align: center;
    font-family: arial, sans-serif;
}

#students {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
}

#students td, #students th {
    border: 1px solid #ddd;
    padding: 8px;
}

#students tr:nth-child(even) {
    background-color: #f2f2f2;
}

#students tr:hover {
    background-color: #ddd;
}

#students th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
}

.text-title-table {
    text-align: center !important;
    font-weight: bold;
    background-color: #bee5eb;
}

.border-left-right {
    border-right: 0.7px solid black !important;
    border-left: 0.7px solid black !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}