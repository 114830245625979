h1 {
    color: #27aedb;
    text-align: center;
}

html, body {
    height: 100%;
    margin: 0;
}

body {
    font-family: arial, sans-serif;
}

#root {
    min-height: 100%;
}

.base-section {
    padding-bottom: 15px;
}


.mt-3 {
    margin-top: 15px;
}

.mt-4 {
    margin-top: 24px;
}

.home-page-root {
    height: 100vh;
    background-image: url('../styles/img/wallpaper-art-image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.home-page-profile-title {
    color: inherit;
    text-decoration: none;
}

.home-page-buttons {
    width: 250px;
    height: 50px;
    align-items: center;
    background-image: url('../styles/img/button-yellow.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
}

.home-page-container {
    width: 100%;
    height: 100%;
}

.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    vertical-align: center;
    height: 90%;
}

.home-page-buttons:hover,
.home-page-buttons:focus {
  box-shadow: 0 0.5em 0.5em -0.4em;
  transform: translateY(-0.25em);
}

.app-bar-ads{
    /*padding-left: 20rem;*/
    padding-right: 5rem;
    margin-left: auto;
}

.app-bar-ads-header{
    font-weight: bold;
}