.dropbtn {
  background: blue;
  color: white;
  padding: 16px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.dropdown {
  position: relative;
  display: inline-block;
  flex-grow: 20;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  user-select: none;
  font-size: medium;
  font-weight: bolder;
  color: #13102c;
}

.dropdown-content a:hover {background-color: wheat}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: blue;
}