.button{
    width: 45px;
    height: 45px;
    background: #c2c8f7;
}
.container{
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}
.box-div{
    margin: 2%;
}
.buttonGroup{
    margin-bottom: 0.8%;
}
