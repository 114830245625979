@import url("../styles/fonts/webfonts/Montserrat.css");

.root-game-multiplier {
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 5%;
}

.multiplier-result-modal-bigButton {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%)
}

.input-text-multiplier {
    text-align: center;
    width: 85%;
    height: 16vh;
    font-size: 7vw;
    border-radius: 10px;
    border: 3px solid #1A01CC;
    background: rgba(255, 220, 220, .3);
    color: white;
    unicode-bidi:bidi-override;
}

.input-text-multiplier input :focus {
    border-radius: 10px;
    border: 2px solid #e3d81c;
    outline: none;
}

.number-text-multiplier {
    font-family: Montserrat;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
}

.container-input-number-multiplier {
    width: 100%;
    color: white;
    /*margin: 0 auto;*/
}

.number-td-multiplier {
    width: 6vw;
}

.number-symbol-multiplier {
    text-align: right;
    margin-top: 5vw;
    padding-right: 2vw;
    color: white;
}

.submit-button-multiplier {
    margin-top: 3vh;
}

.submit-button-multiplier-root {
    height: 15vh;
    width: 30vw;
    background-image: url(../styles/img/button-yellow.png);
    background-size: 100% 100%;
}

.submit-button-multiplier-label {
    font-size: 3vw;
    font-weight: bold;
    color: white;
}


.multiplier-result-modal {
    text-align: center;
}

.multiplier-result-modal-background-img {
    width: 100%;
    height: 100%;
    background-image: url(../styles/img/wallpaper-art-image.jpg);
    background-attachment: fixed;
    background-size: 100% 100%;
    max-width: 100vw;
    min-height: 100vh;
}

.multiplier-result-modal-app-bar {

}

.multiplier-result-modal-title-form {
    margin-left: 2vh;
    flex: 1;
    color: white;
    text-decoration: none;
    user-select: none;
}

.multiplier-result-modal-open-all-result-modal {
    color: white;
    user-select: none;
}

.multiplier-result-modal-body {
    text-align: center;
    margin-top: 11vh;
}

.multiplier-result-modal-paper {
    border-radius: 10px;
    border: 2px solid #3a21ff;
    width: 96%;
    padding: 1vw;
    background: rgba(255, 245, 3, 0.2);
    color: white;
    margin: 1vh;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    margin-top: 1.5vw;
}

.multiplier-result-modal-big-paper {
    border-radius: 10px;
    border: 3px solid #3a21ff;
    font-size: 3vw;
    width: 40%;
    padding: 10px;
    background: rgba(255, 220, 220, .3);
    color: white;
    margin: 1vh;
    font-weight: bold;
    font-family: Montserrat;
    user-select: none;
}

.multiplier-result-modal-button {
    text-align: center;
    color: white;
    width: 20vw;
    margin: 3vh;
    font-size: 3vw;
    font-weight: bold;
    background-image: url(../styles/img/button-yellow.png);
    background-size: 100% 100%;
}

.multiplier-result-modal-button-center {
    width: 90%;
    margin: 0 auto;
}

.multiplier-root-container {
    width: 100%;
    height: 100%;
    background-image: url(../styles/img/wallpaper-art-image.jpg);
    background-attachment: fixed;
    background-size: 100% 100%;
    max-width: 100vw;
    min-height: 100vh;
}

.multiplier-result-modal-paper-box {
    padding: 1vw;
    background-color: transparent;
    text-align: center;
}

.multiplier-result-modal-MinContainer {
    background: rgba(103,142,255,0.44);
    border-radius: 100px;
    margin-top: 5px;
    background-size: 100% 100%;
}

.multiplier-games-result-modal-button {
    width: 200px;
    height: 50px;
    background: url(../styles/img/button-yellow.png)  center center no-repeat;
    background-size: contain;
}